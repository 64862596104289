import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue from "bootstrap-vue";
Vue.config.productionTip = false;

import "./assets/styles/typography.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "./assets/styles/main.scss";

import VueObserveVisibility from "vue-observe-visibility";
import i18n from "./i18n";
Vue.use(VueObserveVisibility);

import VueFullPage from "vue-fullpage.js";

Vue.use(VueFullPage);

var VueScrollTo = require("vue-scrollto");

Vue.use(VueScrollTo);

import AOS from "aos";
import "aos/dist/aos.css";
Vue.use(BootstrapVue);

new Vue({
  router,
  store,
  i18n,
  mounted() {
    AOS.init();
  },
  render: (h) => h(App),
}).$mount("#app");
