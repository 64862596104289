import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/mail",
    name: "Mail",
    component: () =>
      import(/* webpackChunkName: "mail" */ "../views/Email.vue"),
  },
  {
    path: "/formulairecontact",
    name: "Contacter",
    component: () =>
      import(/* webpackChunkName: "contacter" */ "../views/Contacter.vue"),
  },
  {
    path: "/formulairerh",
    name: "Rejoindre",
    component: () =>
      import(/* webpackChunkName: "rejoindre" */ "../views/Rejoindre.vue"),
  },
  {
    path: "/marque",
    name: "Marque",
    component: () =>
      import(/* webpackChunkName: "marque" */ "../views/Marque.vue"),
  },
  {
    path: "/benefits",
    name: "Benefits",
    component: () =>
      import(/* webpackChunkName: "benefits" */ "../views/Benefits.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
