<template>
  <router-view></router-view>
</template>

<script>
import gsap from "gsap";
export default {
  data() {
    return {
      circleTween: null,
    };
  },
  watch: {
    "$i18n.locale"(value) {
      if (value == "ar") {
        document.body.classList.add("ar");
      } else {
        document.body.classList.remove("ar");
      }
    },
  },
  methods: {
    isInViewport(el, offset = 0) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom - offset <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    isonTop(el, offset = 0) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom - offset <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    onScroll() {
      if (
        document.querySelector(".section6__bottle") ||
        document.querySelector(".fixed__bottle")
      ) {
        const section6 = document.querySelector(".section6__textcontent__btn");

        const isVisible = this.isInViewport(section6);
        let circleClass = document.querySelector(".circle-animated");
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          circleClass.classList.add("hide");
        } else {
          circleClass.classList.remove("hide");
        }

        if (
          isVisible &&
          window.innerHeight + window.scrollY <= document.body.offsetHeight
        ) {
          gsap.to(".fixed__bottle", { opacity: 0, duration: 1 });
          gsap.to(".section6__bottle", { x: "-50%", y: "0%", duration: 1 });
        } else {
          gsap.to(".fixed__bottle", { opacity: 1, duration: 1 });
          gsap.to(".section6__bottle", { x: "-50%", y: "150%", duration: 1 });
        }
      }
      if (document.querySelector(".fixed__bottle")) {
        let fixedBottle = document.querySelector(".fixed__bottle");
        if (window.scrollY > 100) {
          fixedBottle.classList.add("fixed__bottle__lg");
        } else {
          fixedBottle.classList.remove("fixed__bottle__lg");
        }
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll, {
      passive: true,
    });
    if (localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang");
    } else {
      this.$i18n.locale = "fr";
    }
    if (this.$i18n.locale == "ar") {
      document.body.classList.add("ar");
    }
  },
  destroyed() {
    document.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss" scoped></style>
